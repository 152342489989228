import React from "react"
import "./../../../styles/bulma-styles.scss"

import KabudaleDB from "../../../compositions/Solutions/kabudale-db";


const Kabudale = () => (
  <KabudaleDB />
)

export default Kabudale
