import React from "react"
import { Link } from "gatsby"
import "../../styles/bulma-styles.scss"
import "./index.scss"
import "./../Home/index.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

class KabudaleDB extends React.Component{

  componentDidMount(){
    
  }

  render(){
    const seoData = {
      title: 'MatSci AI | Materials Costimizer',
      description: 'Finding the cheapest route to manufacture high quality products your customers demand is as easy as clicking a button.',
      lang: 'en',
      metaData:{
        
      }
    }
    
    return (
      <Layout>
        <SEO {...seoData} />
        <div className="floating-content-section">
          <div className="small-header">
            Get all the data you need<span class="blinking-cursor"></span>
          </div>
          <div className="primary-header">
            KabuddleDB
          </div>
          <div className="secondary-header">
            Access to our premium database of more than 150,000 materials and chemicals data for free.
          </div>
        </div>

        <section className="front">
          <img className="videoBG" src="/material-costimizer.jpg" alt="Machine learning Solution for materials industries"/>
          <div className="video-overlay" />
        </section>
        <section className="front-bg" />

        <div className="material-costimizer-section">
          <div className="section-1">
            <div className="columns" style={{ justifyContent: 'center', alignItems: 'center'}}>
              <div className="column">
                <div className="mini-header">
                It helps<span class="blinking-cursor-1"></span>
                </div>
                <div className="heading-1" data-aos="slide-up">
                Discovering Novel Materials & Chemicals
                </div>

                <div className="text-1" data-aos="slide-up">
                Relevant and accurate data will help to refine research hypothesis and fasten materials discovery and chemicals discovery.
                </div>
              </div>
              <div className="column" data-aos="slide-up">
                <img style={{ margin: '0 auto'}} src="/finding-raw-material.jpg" alt="Knowledge Graph enabled AI solution"/>
              </div>
            </div>
          </div>

          <div className="section-1">
            <div className="columns" style={{ alignItems: 'center', justifyContent: 'center'}}>
            <div className="column" data-aos="slide-up">
            <img style={{ margin: '0 auto'}} src="/customer-requirement.png" alt="Materials Discovery"/>
            </div>
              <div className="column">
                <div className="mini-header">
                It helps<span class="blinking-cursor-1"></span>
                </div>
                <div className="heading-1" data-aos="slide-up">
                Building your own AI models
                </div>

                <div className="text-1" data-aos="slide-up">
                Get the data of various materials and chemicals to build your own AI/ML models to pursue your interest in materials informatics and cheminformatics.
                </div>
              </div>
              
            </div>
          </div>

          <div className="section-1">
            <div className="columns" style={{ alignItems: 'center', justifyContent: 'center'}}>
              <div className="column">
                <div className="mini-header">
                It helps<span class="blinking-cursor-1"></span>
                </div>
                <div className="heading-1" data-aos="slide-up">
                Supplementing you Experimental Study
                </div>

                <div className="text-1" data-aos="slide-up">
                Support your experimental findings with the data scrapped from past high impact factor research papers.
                </div>
              </div>
              <div className="column" data-aos="slide-up">
              <img style={{ margin: '0 auto'}} src="/profitibilty.jpg" alt="Materials Informatics"/>

              </div>
            </div>
          </div>
        </div>


        <div className="lets-talk-ai">
          <div className="aXgt mini-header" data-aos="slide-up">
            It’s your decision!<span class="blinking-cursor"></span>
            </div>
            <div className="aXgt main-heading" data-aos="slide-up">
            Do you want to expedite your R&D?
            </div>
            <div className="description-text" data-aos="slide-up">
            Join our community and get the access to our premium database for free!
            </div>
            <Link className="talk-ai-button" to="/solutions/kabuddle-db/sign-in" data-aos="slide-up">
              <div >
              Explore KabuddleDB
            </div>
            </Link>
          </div>

      </Layout>
    );
  }
}


export default KabudaleDB
